// 
// tables.scss
//

//Table centered (Custom)
.table-centered {
    td {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    .table-action {
        .action-icon {
            color: $gray-600;
            font-size: 1.2rem;
            display: inline-block;
            padding: 0 3px;

            &:hover {
                color: $gray-700;
            }
        }
    }
}