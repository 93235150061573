//
// reboot.scss
//


button, a {
  outline: none !important;
}

// Forms
label {
  font-weight: $font-weight-semibold;
}