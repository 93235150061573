// 
// buttons.scss
//

.btn-rounded {
    border-radius: 2em;
}

// Button Shadows
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-shadow($value);
    }
}